<template>
  <ul role="list" class="divide-y divide-gray-100">
    <li v-for="teacher in teachers" :key="teacher.email" class="relative flex justify-between gap-x-6 py-2"
      @click.prevent="removeTeacher(teacher)" @keyup="removeTeacher(teacher)">
      <div class="flex min-w-0 gap-x-4 items-center">
        <img class="h-12 w-12 flex-none rounded-full bg-gray-50" :src="teacher.temporary_profile_picture_url" alt=""
          v-if="teacher.temporary_profile_picture_url" />
        <UserCircleIcon class="h-12 w-12  text-gray-300" aria-hidden="true" v-else />
        <div class="min-w-0 flex-auto">
          <p class="text-sm font-semibold leading-5 text-gray-900">
            <span class="absolute inset-x-0 -top-px bottom-0" />
            {{ teacher.user?.name }}
          </p>
          <p class="flex text-xs leading-5 text-gray-500">
            <a :href="`mailto:${teacher.user?.email}`" class="relative truncate hover:underline">{{ teacher.user?.email
              }}</a>
          </p>
        </div>
      </div>
      <div class="flex shrink-0 items-center gap-x-1">
        <div class="hidden sm:flex sm:flex-col sm:items-end">
          <p class="text-sm leading-6 text-gray-900">{{ teacher.role?.name }}</p>
        </div>
        <span class="status status1 !mx-0" v-if="teacher.pivot?.needs_accommodation">herbergement</span> <span
          class="status status1" v-if="teacher.pivot?.reimbursed_transportation">transport</span>
        <button class="text-indigo-600 hover:text-indigo-900 ml-2"
          @click.prevent="displayConfirmDeleteDialog = true; idTypeToEdit = teacher.id;">
          <XMarkIcon class="-ml-0.5 h-5 w-5" aria-hidden="true" /><span class="sr-only">, {{ teacher.user.name }}</span>
        </button>
      </div>
    </li>
  </ul>
  <ConfirmationDialog @confirm="deleteItem(idTypeToEdit, type)" v-if="displayConfirmDeleteDialog"
    :title="confirmTexts[0].title" :message="confirmTexts[0].message"
    :primaryActionLabel="confirmTexts[0].primaryActionLabel"
    :secondaryActionLabel="confirmTexts[0].secondaryActionLabel" @close="displayConfirmDeleteDialog = false" />
  <SimpleNotification :show="showNotification" :type="notificationType" :message="notificationTitle"
    :detail="notificationMessage" @close="showNotification = false" />
</template>

<script setup>
/* eslint-disable */
import { ref } from 'vue';
import { ChevronRightIcon, UserCircleIcon, XCircleIcon } from '@heroicons/vue/20/solid';
import { XMarkIcon } from '@heroicons/vue/24/outline';
import ConfirmationDialog from '@/components/dialogs/ConfirmationDialog.vue';
import SimpleNotification from '@/components/notifications/SimpleNotification.vue';
import client from '@/api/client';
import { useRoute } from 'vue-router';

const props = defineProps({
  teachers: Object,
  idTraining: [Number, String],
  type: {
    type: String,
    required: true,
  },
});
const emit = defineEmits(['close', 'confirm']);

const confirmTexts = [
  {
    title: 'Supprimer cet item ?',
    message: 'Voulez-vous supprimer cet item',
    primaryActionLabel: 'supprimer',
    secondaryActionLabel: 'annuler'
  },
]
const route = useRoute();
const showNotification = ref(false);
const notificationType = ref('success');
const notificationTitle = ref('');
const notificationMessage = ref('');
const idTypeToEdit = ref(null);
const displayConfirmDeleteDialog = ref(false);

const deleteItem = async (itemToDelete) => {
  let urlws = '';
  if (props.type === 'intervenant') {
    urlws = `api/trainings/${props.idTraining}/teachers/${itemToDelete}`;
  } else if (props.type === 'responsable-pedagogique') {
    urlws = `api/trainings/${props.idTraining}/pedagogical-supervisors/${itemToDelete}`;
  }
  displayConfirmDeleteDialog.value = false;
  try {
    const response = await client.delete(`${urlws}`);
    showNotification.value = true;
    setTimeout(() => { showNotification.value = false; }, 3000);
    notificationType.value = 'success';
    notificationTitle.value = 'Bravo, l\'item a bien été modifié. ';
    // Todo : let response message translated
    notificationTitle.value += response.data.message;
    notificationMessage.value = '';
    emit('confirm');
  } catch (error) {
    notificationType.value = 'error';
    showNotification.value = true;
    notificationTitle.value = 'Erreur lors de l\'enregistrement de l\'item';
    if (error.response) {
      notificationMessage.value = error.response.data.message;
    }
  }
};

const removeTeacher = async (teacher) => {
  displayConfirmDeleteDialog.value = true;
  idTypeToEdit.value = teacher.id;
};
</script>
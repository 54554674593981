<template>
  <SimpleNotification :show="showNotification" :type="notificationType" :message="notificationTitle"
    :detail="notificationMessage" @close="showNotification = false" />
  <button type="button" @click="open = true" class="btn-primary ml-2" v-if="relevantStatuses.length > 0">
    {{ textButtonStatus }}
  </button>
  <TransitionRoot as="template" :show="open">
    <Dialog class="relative !z-100" @close="closeDialog" style="z-index:100">
      <div class="fixed inset-0" />

      <div class="fixed inset-0 overflow-hidden">
        <div class="absolute inset-0 overflow-hidden">
          <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
            <TransitionChild as="template" enter="transform transition ease-in-out duration-500 sm:duration-700"
              enter-from="translate-x-full" enter-to="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700" leave-from="translate-x-0"
              leave-to="translate-x-full">
              <DialogPanel class="pointer-events-auto w-screen max-w-md">
                <div class="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                  <div class="bg-indigo-700 px-4 py-6 sm:px-6">
                    <div class="flex items-center justify-between">
                      <DialogTitle class="text-base font-semibold leading-6 text-white">Choisissez le nouveau statut
                      </DialogTitle>
                      <div class="ml-3 flex h-7 items-center">
                        <button type="button"
                          class="relative rounded-md bg-indigo-700 text-indigo-200 hover:!text-white hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                          @click="open = false">
                          <span class="absolute -inset-2.5" />
                          <span class="sr-only">Close panel</span>
                          <XMarkIcon class="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                    <div class="mt-1">
                    </div>
                  </div>
                  <div class="relative flex-1 px-4 py-6 sm:px-6">
                    Statut actuel : <span :class="'status' +
                      training.status?.id" class="status">
                      {{ training.status?.name }}
                    </span>
                    <fieldset aria-label="Server size" class="mt-10">
                      <RadioGroup v-model="statusSelected" class="space-y-4">
                        <RadioGroupOption as="template" v-for="plan in relevantStatuses" :key="plan.id" :value="plan"
                          v-slot="{ active, checked }">
                          <div
                            :class="[active ? 'border-indigo-600 ring-2 ring-indigo-600' : 'border-gray-300', 'relative block cursor-pointer rounded-lg border bg-white px-6 py-4 shadow-sm focus:outline-none sm:flex sm:justify-between']">
                            <span class="flex items-center">
                              <span class="flex flex-col text-sm">
                                <span class="font-medium text-gray-900">{{ plan.name }}</span>
                              </span>
                            </span>
                            <span
                              :class="[active ? 'border' : 'border-2', checked ? 'border-indigo-600' : 'border-transparent', 'pointer-events-none absolute -inset-px rounded-lg']"
                              aria-hidden="true" />
                          </div>
                        </RadioGroupOption>
                      </RadioGroup>
                    </fieldset>
                    <div class="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 mt-5"
                      v-if="statusSelected?.id === 4">
                      <div class="sm:col-span-3">
                        <label for="start-on" class="block text-sm font-medium leading-6 text-gray-900">Début
                        </label>
                        <div class="mt-2">
                          <input type="datetime-local" name="start-on" id="start-on" v-model="formTraining.start_date"
                            autocomplete="given-name"
                            class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                        </div>
                      </div>

                      <div class="sm:col-span-3">
                        <label for="end-on" class="block text-sm font-medium leading-6 text-gray-900">Fin</label>
                        <div class="mt-2">
                          <input type="datetime-local" name="end-on" id="end-on" v-model="formTraining.end_date"
                            autocomplete="family-name"
                            class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                        </div>
                      </div>
                      <div class="sm:col-span-full">
                        <SelectComponent v-model="formTraining.place" :arrayData="relevantPlaces"
                          :label="'Localisation'" :placeholder="'Choisissez un lieu de stage'" :key="componentKey" />
                      </div>
                    </div>
                  </div>
                  <div class="flex flex-shrink-0 justify-end px-4 py-4">
                    <button type="button"
                      class="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                      @click="$emit('close')">Annuler</button>
                    <button type="submit" @click.prevent="updateStatus(statusSelected)"
                      class="ml-4 inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Enregistrer</button>
                  </div>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup>
/* eslint-disable */
import { ref, onMounted, inject } from 'vue';
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue';
import { ExclamationTriangleIcon, XMarkIcon, ArrowPathIcon, CheckIcon, EyeIcon, NoSymbolIcon } from '@heroicons/vue/24/outline';
import SelectComponent from '@/components/form/SelectComponent.vue';
import fetchTrainingsServices from '@/services/TrainingsServices.js';
import SimpleNotification from '@/components/notifications/SimpleNotification.vue';
import client from '@/api/client';
import { CheckCircleIcon } from '@heroicons/vue/20/solid'
import { RadioGroup, RadioGroupOption } from '@headlessui/vue'

const props = defineProps({
  training: [Array, Object],
  forceStatus: Boolean,
});

const emit = defineEmits(['close', 'confirm']);
const store = inject('store');
const profile = ref();
const open = ref(false);
const statusSelected = ref(null);
const relevantStatuses = ref([]);
const trainingStatuses = ref([]);
const componentKey = ref(0);
const showNotification = ref(false);
const notificationType = ref('success');
const notificationTitle = ref('');
const notificationMessage = ref('');
const formTraining = ref([]);
const trainingDeferred = ref(false);
const textButtonStatus = ref('Changer le statut');
const relevantPlaces = ref([]);

const closeDialog = () => {
  open.value = false;
};

const confirmAction = () => {
  emit('confirm'); // Emit the 'confirm' event
};
const updateStatus = (statusId) => {
  if (statusSelected.value.id === 4) {
    deferringTraining();
  } else {
    patchStatus(statusSelected.value.id);
  }
};

const patchStatus = async (statusId) => {
  try {
    const response = await client.patch(`api/trainings/${props.training.id}`, {
      training_status_id: statusId,
    })
    showNotification.value = true;
    setTimeout(() => { showNotification.value = false; }, 3000);
    notificationType.value = 'success';
    notificationTitle.value = 'Bravo, le statut a bien été modifié';
    notificationMessage.value = '';
    emit('confirm');
  } catch (error) {
    notificationType.value = 'error';
    showNotification.value = true;
    notificationTitle.value = 'Erreur lors de l\'enregistrement du statut';
    if (error.response) {
      notificationMessage.value = error.response.data.message;
    }
  }
};
const deferringTraining = async () => {
  try {
    const response = await client.post(`api/training-deferrals`, {
      training_id: props.training.id,
      user_id: profile.value.user_id,
      initial_start_date: props.training.start_date,
      initial_end_date: props.training.end_date,
      new_start_date: formTraining.value.start_date,
      new_end_date: formTraining.value.end_date,
    })
    showNotification.value = true;
    setTimeout(() => { showNotification.value = false; }, 3000);
    notificationType.value = 'success';
    notificationTitle.value = 'Bravo, le report a bien été enregistré';
    notificationMessage.value = '';
    patchPlace();
    emit('confirm');
  } catch (error) {
    notificationType.value = 'error';
    showNotification.value = true;
    notificationTitle.value = 'Erreur lors de l\'enregistrement du report';
    if (error.response) {
      notificationMessage.value = error.response.data.message;
    }
  }
};

const patchPlace = async () => {
  try {
    const response = await client.patch(`api/trainings/${props.training.id}`, {
      training_place_id: formTraining.value.place?.id,
      training_status_id: 4,
    })
    showNotification.value = true;
    setTimeout(() => { showNotification.value = false; }, 3000);
    notificationType.value = 'success';
    notificationTitle.value = 'Bravo, le lieu de la formaion a été modifié';
    notificationMessage.value = '';
    emit('confirm');
  } catch (error) {
    notificationType.value = 'error';
    showNotification.value = true;
    notificationTitle.value = 'Erreur lors de l\'enregistrement du lieu';
    if (error.response) {
      notificationMessage.value = error.response.data.message;
    }
  }
};
onMounted(() => {
  profile.value = JSON.parse(localStorage.getItem('user'));
  fetchTrainingsServices.fetchTrainingStatuses().then((data) => {
    trainingStatuses.value = data;
    componentKey.value += 1;
    if (props.forceStatus) {
      open.value = true;
      relevantStatuses.value = trainingStatuses.value;
    } else {

      if ((props.training.status?.id === 1) && (profile.value.role?.slug === 'administrateur')) {
        textButtonStatus.value = 'Valider';
        relevantStatuses.value.push(trainingStatuses.value.find((s) => s.id === 2));
      }
      if ((props.training.status?.id === 2) && (profile.value.role?.slug === 'gestionnaire' || profile.value.role?.slug === 'administrateur')) {
        textButtonStatus.value = 'Publier';
        relevantStatuses.value.push(trainingStatuses.value.find((s) => s.id === 3));
      }
      if ((props.training.status?.id === 2 || props.training.status?.id === 3 || props.training.status?.id === 4) && (profile.value.role?.slug === 'gestionnaire' || profile.value.role?.slug === 'administrateur') && (new Date(props.training.start_date) > new Date())) {
        textButtonStatus.value = 'Reporter la formation';
        relevantStatuses.value.push(trainingStatuses.value.find((s) => s.id === 4));
        trainingDeferred.value = true;
      }
      if (((props.training.status?.id === 2) || (props.training.status?.id === 3) || (props.training.status?.id === 4)) && profile.value.role?.slug === 'administrateur') {
        textButtonStatus.value = 'Annuler';
        relevantStatuses.value.push(trainingStatuses.value.find((s) => s.id === 6));
      }
      if ((props.training.status?.id === 5) && (profile.value.role?.slug === 'administrateur')) {
        textButtonStatus.value = 'Clôturer';
        relevantStatuses.value.push(trainingStatuses.value.find((s) => s.id === 7));
      }
      if (relevantStatuses.value.length > 1) {
        textButtonStatus.value = 'Changer le statut';
      } else if (relevantStatuses.value.length === 1) {
        statusSelected.value = relevantStatuses.value[0];
      } else if (relevantStatuses.value.length === 0) {
        statusSelected.value = relevantStatuses.value[0];
      }
    }
  });

});
fetchTrainingsServices.fetchTrainingPlaces().then((data) => {
  relevantPlaces.value = data;
});
</script>

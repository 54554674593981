<template>
    <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 mt-12">
        <div class="mx-auto max-w-12xl"></div>
        <div class="border-b border-gray-200 pb-5 sm:flex sm:items-center sm:justify-between">
            <h3 class="text-base font-semibold leading-6 text-gray-900">Mes formations passées</h3>
        </div>
        <div class="my-4">
            <SmallLoader v-if="isLoading" :text="'Chargement en cours'" />
        </div>
        <ul role="list" class="divide-y divide-gray-100">
            <li v-for="training in _.orderBy(relatedValidatedTrainings, 'start_date', 'desc')" :key="training.id"
                class="flex flex-wrap items-center justify-between gap-x-6 py-5">
                <div class="min-w-0 w-full sm:w-1/2">
                    <div class="flex items-start gap-x-3">
                        <router-link :to="'/formations/' + training.training_id">
                            <p class="text-sm font-semibold leading-6 text-gray-900">{{ training.training_name }}</p>
                        </router-link>
                        <p class="status status5 lowercase">
                            {{ training.registration_status }}</p>
                    </div>
                    <div class="mt-1 flex flex-wrap items-center gap-x-2 text-xs leading-5 text-gray-500">
                        <p class="whitespace-nowrap">
                            Début le le <time :datetime="training.start_date">{{
                                moment(training.start_date).format("Do MMM YYYY") }}</time>
                        </p>
                        <svg viewBox="0 0 2 2" class="h-0.5 w-0.5 fill-current">
                            <circle cx="1" cy="1" r="1" />
                        </svg>
                        <p class="sm:truncate">Termine le {{ moment(training.end_date).format("Do MMM YYYY") }}</p>
                    </div>
                </div>
                <div class="flex flex-none items-center gap-x-4">
                    <router-link :to="'/formations/' + training.training_id"> <button
                            class="mt-3 sm:mt-0 rounded-md bg-white px-2.5 py-1.5  text-xs sm:text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:block">Voir
                            la formation<span class="sr-only">, {{ training.title }}</span></button></router-link>
                </div>
            </li>
        </ul>
        <ul role="list" class="divide-y divide-gray-100">
            <li v-for="training in _.orderBy(relatedPreInscriptionTrainings, 'start_date', 'desc')" :key="training.id"
                class="flex flex-wrap items-center justify-between gap-x-6 py-5">
                <div class="min-w-0 w-full sm:w-1/2">
                    <div class="flex items-start gap-x-3">
                        <router-link :to="'/formations/' + training.training_id">
                            <p class="text-sm font-semibold leading-6 text-gray-900">{{ training.training_name }}</p>
                        </router-link>
                        <p class="status status4 lowercase">
                            {{ training.registration_status }}</p>

                    </div>
                    <div class="mt-1 flex  flex-wrap items-center gap-x-2 text-xs leading-5 text-gray-500">
                        <p class="whitespace-nowrap">
                            Début le le <time :datetime="training.start_date">{{
                                moment(training.start_date).format("Do MMM YYYY") }}</time>
                        </p>
                        <svg viewBox="0 0 2 2" class="h-0.5 w-0.5 fill-current">
                            <circle cx="1" cy="1" r="1" />
                        </svg>
                        <p class="sm:truncate">Termine le {{ moment(training.end_date).format("Do MMM YYYY") }}</p>
                    </div>
                </div>
                <div class="flex flex-none items-center gap-x-4">
                    <router-link :to="'/formations/' + training.training_id"> <button
                            class="text-xs sm:text-sm mt-3 sm:mt-0 rounded-md bg-white px-2.5 py-1.5 font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:block">Voir
                            la formation<span class="sr-only">, {{ training.title }}</span></button></router-link>
                </div>
            </li>
        </ul>
        <ul role="list" class="divide-y divide-gray-100">
            <li v-for="training in _.orderBy(relatedRefusedTrainings, 'start_date', 'desc')" :key="training.id"
                class="flex  flex-wrap items-center justify-between gap-x-6 py-5">
                <div class="min-w-0 w-full sm:w-1/2">
                    <div class="flex items-start gap-x-3">
                        <router-link :to="'/formations/' + training.training_id">
                            <p class="text-sm font-semibold leading-6 text-gray-900">{{ training.training_name }}</p>
                        </router-link>
                        <p class="status status6 lowercase">
                            {{ training.registration_status }}</p>
                    </div>
                    <div class="mt-1 flex  flex-wrap items-center gap-x-2 text-xs leading-5 text-gray-500">
                        <p class="whitespace-nowrap">
                            Début le le <time :datetime="training.start_date">{{
                                moment(training.start_date).format("Do MMM YYYY") }}</time>
                        </p>
                        <svg viewBox="0 0 2 2" class="h-0.5 w-0.5 fill-current">
                            <circle cx="1" cy="1" r="1" />
                        </svg>
                        <p class="sm:truncate">Termine le {{ moment(training.end_date).format("Do MMM YYYY") }}</p>
                    </div>
                </div>
                <div class="flex flex-none items-center gap-x-4">
                    <router-link :to="'/formations/' + training.training_id"> <button
                            class="mt-3 sm:mt-0 rounded-md bg-white px-2.5 py-1.5  text-xs sm:text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:block">Voir
                            la formation<span class="sr-only">, {{ training.title }}</span></button></router-link>
                </div>
            </li>
        </ul>
        <ul role="list" class="divide-y divide-gray-100">
            <li v-for="training in _.orderBy(relatedWaitingTrainings, 'start_date', 'desc')" :key="training.id"
                class="flex flex-wrap  items-center justify-between gap-x-6 py-5">
                <div class="min-w-0 w-full sm:w-1/2">
                    <div class="flex flex-wrap items-start gap-x-3">
                        <router-link :to="'/formations/' + training.training_id">
                            <p class="text-sm font-semibold leading-6 text-gray-900">{{ training.training_name }}</p>
                        </router-link>
                        <p class="status status3 lowercase">
                            {{ training.registration_status }}</p>

                    </div>
                    <div class="mt-1 flex  flex-wrap items-center gap-x-2 text-xs leading-5 text-gray-500">
                        <p class="whitespace-nowrap">
                            Début le le <time :datetime="training.start_date">{{
                                moment(training.start_date).format("Do MMM YYYY") }}</time>
                        </p>
                        <svg viewBox="0 0 2 2" class="h-0.5 w-0.5 fill-current">
                            <circle cx="1" cy="1" r="1" />
                        </svg>
                        <p class="sm:truncate">Termine le {{ moment(training.end_date).format("Do MMM YYYY") }}</p>
                    </div>
                </div>
                <div class="flex flex-none items-center gap-x-4">
                    <router-link :to="'/formations/' + training.training_id"> <button
                            class="mt-3 sm:mt-0 rounded-md bg-white px-2.5 py-1.5  text-xs sm:text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:block">Voir
                            la formation<span class="sr-only">, {{ training.title }}</span></button></router-link>
                </div>
            </li>
        </ul>
        <AlertMessage type="info" title="Aucune formation passée pour le moment" class="mb-4"
            v-if="!isLoading && relatedPreInscriptionTrainings.length === 0 && relatedRefusedTrainings && relatedWaitingTrainings" />
    </div>
</template>

<script setup>
/* eslint-disable */
import { ref } from 'vue';
import moment from 'moment';
import { onMounted } from 'vue';
import fetchTrainingsServices from '@/services/TrainingsServices.js';
import { orderBy } from 'lodash';
const _ = require("lodash");
import client from '@/api/client';
import SmallLoader from '@/components/app/SmallLoader.vue';
import AlertMessage from '@/components/alerts/AlertMessage.vue';

const statuses = {
    Complet: 'text-green-700 bg-green-50 ring-green-600/20',
    'En cours': 'text-gray-600 bg-gray-50 ring-gray-500/10',
    Terminée: 'text-yellow-800 bg-yellow-50 ring-yellow-600/20',
}
const relevantStatuses = ref([]);
const relatedValidatedTrainings = ref([]);
const relatedPreInscriptionTrainings = ref([]);
const relatedRefusedTrainings = ref([]);
const relatedWaitingTrainings = ref([]);
const isLoading = ref(false);
const componentKey = ref(0);

onMounted(async () => {
    isLoading.value = true;
    fetchTrainingsServices.fetchTrainingStatuses().then((data) => {
        relevantStatuses.value = data;
    });
    try {
        const response = await client.get(`api/trainee/trainings/past?status=preinscription`);
        relatedPreInscriptionTrainings.value = response.data;
        componentKey.value += 1;
        isLoading.value = false;
    } catch (error) {
        console.error(error);
    }
    try {
        const response = await client.get(`api/trainee/trainings/past?status=valide`);
        relatedValidatedTrainings.value = response.data;
        componentKey.value += 1;
        isLoading.value = false;
    } catch (error) {
        console.error(error);
    }
    try {
        const response = await client.get(`api/trainee/trainings/past?status=refuse`);
        relatedRefusedTrainings.value = response.data;
        componentKey.value += 1;
        isLoading.value = false;
    } catch (error) {
        console.error(error);
    }

});
</script>
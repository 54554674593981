<template>
    <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 mt-12">
        <div class="mx-auto max-w-12xl">
            <div class="border-b border-gray-200 pb-5 sm:flex sm:items-center sm:justify-between">
                <h3 class="text-base font-semibold leading-6 text-gray-900"><span v-if="totalItems > 0"
                        class="badge badge_unselected !bg-purple-100">{{
                            totalItems }}</span> {{ title }}</h3>
            </div>
            <div class="my-4">
                <SmallLoader v-if="isLoading" :text="'Chargement en cours'" />
            </div>
            <ul role="list" class="divide-y divide-gray-100" v-if="upcomingTrainings.length > 0">
                <li v-for="training in upcomingTrainings" :key="training.id"
                    class="relative flex flex-wrap justify-between gap-x-6 py-5 items-center">
                    <div class="leading6 min-w-0 w-full sm:w-1/2">
                        <router-link :to="'/formations/' + training.id">
                            <p class="text-sm font-semibold leading-6 text-gray-900">
                                <a :href="training.href" class="hover:underline">{{ training.title }}</a>
                            </p>
                        </router-link>
                        <div class="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                            <p class="flex items-center mb-1">
                                <MapPinIcon class="h-4 w-4 mr-1" /><a class="hover:underline leading-4">{{
                                    training.place
                                }}</a>
                            </p>
                        </div>
                        <p class="text-xs text-gray-500 flex items-center mb-1">
                            <CalendarIcon class="h-4 w-4 mr-1" />
                            du {{ moment(training?.start_date).format("Do MMM YY") }} au {{
                                moment(training?.end_date).format("Do MMM YY") }}
                        </p>
                    </div>
                    <dl class="flex w-full flex-none justify-between gap-x-2 sm:w-auto">
                        <div class="text-xs text-gray-500 text-right">

                            <p class="mt-2 sm:mt-1"><span class="status status3">{{ training.confirmed_count }}
                                    inscrits</span>
                            </p>
                        </div>

                        <div class="flex -space-x-0.5">
                            <dt class="sr-only">Stagiaires</dt>
                            <dd v-for="trainee in training.trainees" :key="trainee.id">
                                <img class="h-6 w-6 rounded-full bg-gray-50 ring-2 ring-white" :src="trainee.imageUrl"
                                    :alt="trainee.fullname" />
                            </dd>
                        </div>
                        <router-link :to="'/formations/' + training.id"><button type="button"
                                class="hidden rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:block">
                                Voir<span class="sr-only">, {{ training.training_title }}</span></button></router-link>
                    </dl>
                </li>
            </ul>
            <AlertMessage type="info" title="Aucune formation pour le moment" class="mb-4"
                v-if="!isLoading && upcomingTrainings.length === 0" />
            <Pagination :current-page="currentPage" :total-pages="totalPages" @change-page="changePage" />
        </div>
    </div>
</template>

<script setup>
/* eslint-disable */
import { ref, onMounted } from 'vue';
import { ExclamationTriangleIcon, CheckCircleIcon, UserIcon, BuildingLibraryIcon } from '@heroicons/vue/24/outline';
import Pagination from '@/components/lists/PaginationTables.vue';
import SmallLoader from '@/components/app/SmallLoader.vue';
import AlertMessage from '@/components/alerts/AlertMessage.vue';
import moment from 'moment';
import 'moment/locale/fr';
import client from '@/api/client';
import { MapPinIcon, CalendarIcon } from '@heroicons/vue/24/outline';

moment.locale('fr');

const props = defineProps({
    title: [String],
    type: [String],
});
const profile = ref(null);
const currentPage = ref(1);
const totalPages = ref(1);
const totalItems = ref(0);
const pageSize = 7;
const isLoading = ref(false);
const upcomingTrainings = ref([]);

const changePage = async (page) => {
    let urlws = '';
    isLoading.value = true;
    if (props.type === 'next-15-days') {
        urlws = 'api/trainings/next-15-days';
    }


    try {
        const response = await client.get(`${urlws}`, {
            params: { page: page, per_page: pageSize },
        });
        upcomingTrainings.value = response.data.data;
        currentPage.value = Number(response.data.current_page);
        totalPages.value = response.data.last_page;
        totalItems.value = response.data.total;
        isLoading.value = false;
    } catch (error) {
        console.error('Failed to fetch trainings:', error);
    }
};

onMounted(async () => {
    profile.value = JSON.parse(localStorage.getItem('user'));
    changePage(1);
});
</script>

<template>
  <main>
    <div class="mx-auto max-w-7xl px-4 py-16 sm:px-6 lg:px-8">
      <div id="pro" class="mb-20">
        <h2 class="text-base font-semibold leading-7 text-gray-900">Informations professionnelles de {{
          trainee.user.name }}
        </h2>
        <p class="mt-1 text-sm leading-6 text-gray-500"></p>

        <dl class="mt-6 space-y-3 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">
          <div class="pt-6 sm:flex">
            <dt class="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Situation professionnelle</dt>
            <dd class="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              <div class="text-gray-900">{{
                trainee.professional_situation?.name }}
              </div>
            </dd>
          </div>
          <div class="pt-6 sm:flex">
            <dt class="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Catégorie professionnelle</dt>
            <dd class="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              <div class="text-gray-900">{{
                trainee.professional_category?.name }}</div>
            </dd>
          </div>
          <div class="pt-6 sm:flex">
            <dt class="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Secteur professionnel</dt>
            <dd class="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              <div class="text-gray-900">{{ trainee.activity_sector?.name }}</div>
            </dd>
          </div>
          <div class="pt-6 sm:flex">
            <dt class="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Diplôme</dt>
            <dd class="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              <div class="text-gray-900">{{ trainee.degree?.name }}</div>
            </dd>
          </div>
          <div class="pt-6 sm:flex">
            <dt class="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Nom de
              l'entreprise</dt>
            <dd class="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              <div class="text-gray-900">{{ trainee.company_name }}</div>
            </dd>
          </div>
          <div class="pt-6 sm:flex">
            <dt class="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Taille de l'entreprise</dt>
            <dd class="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              <div class="text-gray-900">{{ trainee.company_size_id }}</div>
            </dd>
          </div>
          <div class="pt-6 sm:flex">
            <dt class="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Code postal de l'entreprise</dt>
            <dd class="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              <div class="text-gray-900">{{ trainee.company_zip_code }}</div>
            </dd>
          </div>
        </dl>
      </div>

      <!-- Unions section -->
      <div id="unions" class="mb-20">
        <h2 class="text-base font-semibold leading-7 text-gray-900">Syndicats</h2>
        <p class="mt-1 text-sm leading-6 text-gray-500"></p>

        <div v-if="!trainee.unions || trainee.unions.length === 0" class="rounded-md bg-blue-50 p-4 mt-8">
          <div class="flex">
            <div class="flex-shrink-0">
              <InformationCircleIcon class="h-5 w-5 text-blue-400" aria-hidden="true" />
            </div>
            <div class="ml-3 flex-1 md:flex md:justify-between">
              <p class="text-sm text-blue-700">Aucun syndicat n'est actuellement renseigné pour ce profil.</p>
            </div>
          </div>
        </div>

        <ul v-else role="list" class="divide-y divide-gray-100">
          <li v-for="union in trainee.unions" :key="union.name" class="py-4">
            <div class="flex items-center gap-x-3">
              <h3 class="flex-auto truncate text-sm font-semibold leading-6 text-gray-900">{{ union.name }}</h3>
            </div>
            <p class="mt-3 truncate text-sm text-gray-500">
              Date de début : <span class="text-gray-700">{{ union.pivot.start_date ? formatDate(union.pivot.start_date)
                : 'Non communiquée' }}</span><br>
              Date de fin : <span class="text-gray-700">{{ union.pivot.end_date ? formatDate(union.pivot.end_date) :
                'Non communiquée' }}</span>
            </p>
            <!-- Display badges based on the union status -->
            <div class="mt-2">
              <span v-if="isValidAndCurrent(union.pivot)"
                class="inline-flex items-center rounded-md bg-green-100 px-2 py-1 text-xs font-medium text-green-700">
                En cours
              </span>
              <span v-else-if="isNotValidButCurrent(union.pivot)"
                class="inline-flex items-center rounded-md bg-yellow-100 px-2 py-1 text-xs font-medium text-yellow-800">
                En cours mais non valide
              </span>
              <span v-else
                class="inline-flex items-center rounded-md bg-gray-100 px-2 py-1 text-xs font-medium text-gray-600">
                Ni en cours ni valide
              </span>
            </div>
          </li>
        </ul>
      </div>

      <!-- Mandates section -->
      <div id="mandates">
        <h2 class="text-base font-semibold leading-7 text-gray-900">Mandats</h2>
        <p class="mt-1 text-sm leading-6 text-gray-500"></p>

        <div v-if="!trainee.mandates || trainee.mandates.length === 0" class="rounded-md bg-blue-50 p-4 mt-8">
          <div class="flex">
            <div class="flex-shrink-0">
              <InformationCircleIcon class="h-5 w-5 text-blue-400" aria-hidden="true" />
            </div>
            <div class="ml-3 flex-1 md:flex md:justify-between">
              <p class="text-sm text-blue-700">Aucun mandat n'est actuellement renseigné pour ce profil.</p>
            </div>
          </div>
        </div>

        <ul v-else role="list" class="mt-3 grid grid-cols-1 gap-5 sm:grid-cols-2 sm:gap-6 lg:grid-cols-3 pt-10">
          <li v-for="(mandate) in trainee.mandates" :key="mandate.name" class="col-span-1 flex rounded-md shadow-sm">
            <div class="flex flex-1 items-center justify-between truncate rounded-md border border-gray-200 bg-white">
              <div class="flex-1 truncate px-4 py-4 text-sm">
                <div class="font-semibold text-gray-900 hover:text-gray-600 mb-3">{{ mandate.name }}</div>
                <p class="text-gray-500">{{ mandate.label }}</p>
                <p><span class="status status2" v-if="mandate.pivot?.council_id">{{
                  getCouncil(mandate.pivot?.council_id) }}</span></p>
                <p><span class="status status3" v-if="mandate.pivot.council_section_id">{{
                  getCouncilSection(mandate.pivot.council_section_id) }}</span></p>
              </div>
              <div class="flex-shrink-0 pr-4">
                <span v-if="mandate.pivot?.is_valid === 1" class="ml-2 status status5">actif</span>
                <span v-else class="ml-2 status status1">inactif</span>
              </div>
            </div>
          </li>
        </ul>
      </div>

    </div>
  </main>
</template>

<script setup>
/* eslint-disable */
import { ref, onMounted } from 'vue';
import moment from 'moment';
import 'moment/locale/fr';
import { InformationCircleIcon } from '@heroicons/vue/20/solid';
import fetchPersonalInfoServices from '@/services/PersonalInfoServices.js';

defineProps({
  trainee: Object,
});

const relevantCouncils = ref([]);
const relevantCouncilSections = ref([]);

// Format date with moment.js in French
const formatDate = (dateString) => {
  return moment(dateString).locale('fr').format('LL');
};

// Check if union is valid and current (green badge)
const isValidAndCurrent = (pivot) => {
  if (pivot.is_valid !== 1) return false; // Must be valid (is_valid = 1)
  if (!pivot.end_date) return true; // No end date means still ongoing
  const today = moment();
  const end = moment(pivot.end_date);
  return today.isSameOrBefore(end); // Ongoing if end_date is in the future
};

// Check if union is not valid but still ongoing (orange badge)
const isNotValidButCurrent = (pivot) => {
  if (pivot.is_valid === 1) return false; // Should not be valid
  if (!pivot.end_date) return true; // No end date means it's still ongoing
  const today = moment();
  const end = moment(pivot.end_date);
  return today.isSameOrBefore(end); // Ongoing but not valid if end_date is in the future
};

const getCouncil = ((id) => {
  const council = relevantCouncils.value.find(item => item.id === id);
  if (council) {
    return council.name;
  }
  return '';
});

const getCouncilSection = ((id) => {
  const councilSection = relevantCouncilSections.value.find(item => item.id === id);
  if (councilSection) {
    return councilSection.name;
  }
  return '';
});

// Fetch councils and sections data
onMounted(() => {
  fetchPersonalInfoServices.fetchCouncils().then((data) => {
    relevantCouncils.value = data;
  });
  fetchPersonalInfoServices.fetchCouncilSections().then((data) => {
    relevantCouncilSections.value = data;
  });
});
</script>

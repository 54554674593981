<template>
    <div>
        <AlertMessage :title="alertTitle" v-if="displayAlert" type="error" />
        <legend class="text-sm font-semibold leading-6 text-gray-900 mt-10">Choisissez la régie</legend>
        <RadioGroup v-model="selectedPaymentOrder" :key="paymentOrders"
            class="mb-10 mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4">
            <RadioGroupOption as="template" v-for="paymentOrder in paymentOrders" :key="paymentOrder.order_number"
                :value="paymentOrder" :aria-label="paymentOrder.title" @click="checkPaymentOrder()"
                :aria-description="`${paymentOrder.title}`" v-slot="{ active, checked }">
                <div
                    :class="[active ? 'border-indigo-600 ring-2 ring-indigo-600' : 'border-gray-300', 'relative flex cursor-pointer rounded-lg border bg-white p-4 shadow-sm focus:outline-none']">
                    <span class="flex flex-1">
                        <span class="flex flex-col">
                            <span class="block text-sm font-medium text-gray-900">{{ paymentOrder.order_number }}</span>
                        </span>
                    </span>
                    <CheckCircleIcon :class="[!checked ? 'invisible' : '', 'h-5 w-5 text-indigo-600']"
                        aria-hidden="true" />
                    <span
                        :class="[active ? 'border' : 'border-2', checked ? 'border-indigo-600' : 'border-transparent', 'pointer-events-none absolute -inset-px rounded-lg']"
                        aria-hidden="true" />
                </div>
            </RadioGroupOption>
            <div><button v-if="paymentOrders.length < 2" class="btn-primary inline-flex"
                    @click.prevent="createPaymentOrder">Créer une nouvelle régie</button></div>
        </RadioGroup>
        <legend class="text-sm font-semibold leading-6 text-gray-900 mt-10">Choisissez le moyen de paiement</legend>
        <RadioGroup v-model="selectedPaymentMethod"
            class="mb-10 mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4">
            <RadioGroupOption as="template" v-for="paymentMethod in paymentMethods" :key="paymentMethod.id"
                :value="paymentMethod" :aria-label="paymentMethod.title" :aria-description="`${paymentMethod.title}`"
                v-slot="{ active, checked }">
                <div
                    :class="[active ? 'border-indigo-600 ring-2 ring-indigo-600' : 'border-gray-300', 'relative flex cursor-pointer rounded-lg border bg-white p-4 shadow-sm focus:outline-none']">
                    <span class="flex flex-1">
                        <span class="flex flex-col">
                            <span class="block text-sm font-medium text-gray-900">{{ paymentMethod.title }}</span>
                        </span>
                    </span>
                    <CheckCircleIcon :class="[!checked ? 'invisible' : '', 'h-5 w-5 text-indigo-600']"
                        aria-hidden="true" />
                    <span
                        :class="[active ? 'border' : 'border-2', checked ? 'border-indigo-600' : 'border-transparent', 'pointer-events-none absolute -inset-px rounded-lg']"
                        aria-hidden="true" />
                </div>
            </RadioGroupOption>
        </RadioGroup>
        <div class="col-span-full">
            <label for="otherFees" class="block text-sm font-medium leading-6 text-gray-900">Référence du
                paiement</label>
            <div class="relative mt-2 rounded-md shadow-sm mb-5">
                <input type="text" name="reference" id="reference" v-model="formPayment.reference" class="inputSimple"
                    placeholder="Notez la référence du paiement" />
            </div>
        </div>
        <button type="button" class="btn-success w-full inline-flex items-center justify-center"
            @click.prevent="postPayment">Mettre en paiement</button>
    </div>
</template>

<script setup>
/* eslint-disable */
import { ref, onMounted } from 'vue';
import { Listbox, ListboxButton, ListboxLabel, ListboxOption, ListboxOptions } from '@headlessui/vue';
import { CheckIcon, ChevronUpDownIcon, ChevronDownIcon } from '@heroicons/vue/20/solid';
import { RadioGroup, RadioGroupOption } from '@headlessui/vue'
import { CheckCircleIcon } from '@heroicons/vue/20/solid';
import client from '@/api/client';
import AlertMessage from '@/components/alerts/AlertMessage.vue';

let props = defineProps({
    expense: [Array, Object],
})
const emit = defineEmits(['confirm', 'close']);

const formPayment = ref({
    method: '',
    reference: '',
    amount: '',
});
const paymentMethods = [
    { id: 1, title: 'virement' },
    { id: 2, title: 'chèque' },
]
const paymentOrders = ref([]);
const selectedPaymentOrder = ref(null);
const selectedPaymentMethod = ref(paymentMethods[0]);
const displayAlert = ref(false);
const alertTitle = ref('');
const order_number = ref('');

const AllFieldsAreFilled = () => {
    if (!selectedPaymentMethod.value || !formPayment.value.reference || !selectedPaymentOrder.value) {
        alertTitle.value = 'Veuillez remplir tous les champs';
        displayAlert.value = true;
        return false;
    }
    return true;
}

const postPayment = async () => {
    if (!AllFieldsAreFilled()) {
        return;
    } else {
        try {
            const response = await client.post('api/training-expense-payments/attach', {
                training_expense_id: props.expense.id,
                payment_method: selectedPaymentMethod.value.title,
                payment_reference: formPayment.value.reference,
                payment_order_id: selectedPaymentOrder.value.id,
            });
            emit('confirm', 'success', 'Le paiement a été effectué avec succès');
            changeStatus(4);
        } catch (error) {
            console.log('error');
            emit('confirm', 'error', error.response?.data?.message);
        }
    }
}

const checkPaymentOrder = () => {
    if (selectedPaymentOrder.value.id === null) {
        createPaymentOrder();
    }
}

const changeStatus = async (status) => {
    try {
        const response = await client.post(`/api/training-expense-statuses/${status}/attach`, {
            training_expense_id: props.expense.id,
        });
        emit('confirm', 'success', 'Le paiement a été effectué avec succès');
    } catch (error) {
        emit('confirm', 'error', error.response?.data?.message);
    }
}
const createPaymentOrder = async () => {
    try {
        const response = await client.post(`/api/payment-orders`, {
            training_expense_id: props.expense.id,
        });
        paymentOrders.value.push({ id: response.data.id, order_number: response.data.order_number });
        selectedPaymentOrder.value = paymentOrders.value[1];
        emit('confirm', 'success', 'La régie a été créée');
    } catch (error) {
        console.error('Failed to create payment order:', error);
        emit('confirm', 'error', error.response?.data.error);
    }
}

onMounted(async () => {
    try {
        const response = await client.get('api/last-payment-order');
        paymentOrders.value.push({ id: response.data.id, order_number: response.data.order_number });
    } catch (error) {
        console.error('Failed to fetch last paymnt order:', error);
    }
    console.log(props.expense);
    formPayment.value.reference = 'pay' + props.expense.id + '-stagiaire' + props.expense.profile_id;
});
</script>
<template>
  <nav class="flex items-center justify-between border-t border-gray-200 px-4 sm:px-0">
    <div class="-mt-px flex w-0 flex-1">
      <a href="#" @click.prevent="prevPage"
        :class="['inline-flex items-center border-t-2 border-transparent pr-1 pt-4 text-sm font-medium', currentPage === 1 ? 'text-gray-300 cursor-not-allowed' : 'text-gray-500 hover:border-gray-300 hover:text-gray-700']">
        <ArrowLongLeftIcon class="mr-3 h-5 w-5" :class="currentPage === 1 ? 'text-gray-300' : 'text-gray-400'"
          aria-hidden="true" />
        Précédent
      </a>
    </div>
    <div class="hidden md:-mt-px md:flex">
      <a v-for="page in pages" :key="page" href="#" @click.prevent="changePage(page)"
        :class="['inline-flex items-center border-t-2 px-3 pt-4 text-sm font-medium', page === currentPage ? 'border-indigo-500 text-indigo-600' : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700']"
        :aria-current="page === currentPage ? 'page' : null">
        {{ page }}
      </a>
    </div>
    <div class="-mt-px flex w-0 flex-1 justify-end">
      <a href="#" @click.prevent="nextPage"
        :class="['inline-flex items-center border-t-2 border-transparent pl-1 pt-4 text-sm font-medium', currentPage === totalPages ? 'text-gray-300 cursor-not-allowed' : 'text-gray-500 hover:border-gray-300 hover:text-gray-700']">
        Suivant
        <ArrowLongRightIcon class="ml-3 h-5 w-5" :class="currentPage === totalPages ? 'text-gray-300' : 'text-gray-400'"
          aria-hidden="true" />
      </a>
    </div>
  </nav>
</template>

<script setup>
/* eslint-disable */
import { computed } from 'vue';
import { ArrowLongLeftIcon, ArrowLongRightIcon } from '@heroicons/vue/20/solid';

const props = defineProps({
  currentPage: {
    type: Number,
    required: true,
  },
  totalPages: {
    type: Number,
    required: true,
  },
});

const emit = defineEmits(['change-page']);

const changePage = (page) => {
  if (page !== props.currentPage) {
    emit('change-page', page);
  }
};

const prevPage = () => {
  if (props.currentPage > 1) {
    emit('change-page', props.currentPage - 1);
  }
};

const nextPage = () => {
  if (props.currentPage < props.totalPages) {
    emit('change-page', props.currentPage + 1);
  }
};

const pages = computed(() => {
  const pagesArray = [];
  if (props.totalPages <= 7) {
    for (let i = 1; i <= props.totalPages; i += 1) {
      pagesArray.push(i);
    }
  } else if (props.currentPage <= 4) {
    for (let i = 1; i <= 5; i += 1) {
      pagesArray.push(i);
    }
    pagesArray.push('...');
    pagesArray.push(props.totalPages);
  } else if (props.currentPage > props.totalPages - 4) {
    pagesArray.push(1);
    pagesArray.push('...');
    for (let i = props.totalPages - 4; i <= props.totalPages; i += 1) {
      pagesArray.push(i);
    }
  } else {
    pagesArray.push(1);
    pagesArray.push('...');
    for (let i = props.currentPage - 1; i <= props.currentPage + 1; i += 1) {
      pagesArray.push(i);
    }
    pagesArray.push('...');
    pagesArray.push(props.totalPages);
  }
  return pagesArray;
});
</script>

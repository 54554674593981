<template>
    <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 mt-12">
        <div class="mx-auto max-w-12xl">
            <div class="border-b border-gray-200 pb-5 sm:flex sm:items-center sm:justify-between">
                <h3 class="text-base font-semibold leading-6 text-gray-900"><span v-if="totalItems > 0"
                        class="badge badge_unselected !bg-purple-100">{{
                            totalItems }}</span> {{ title }}</h3>
            </div>
            <div class="my-4">
                <SmallLoader v-if="isLoading" :text="'Chargement en cours'" />
            </div>
            <ul role="list" class="divide-y divide-gray-100" v-if="upcomingTrainings.length > 0">
                <li v-for="training in upcomingTrainings" :key="training.id"
                    class="relative flex flex-wrap justify-between gap-x-6 py-5">
                    <div>
                        <router-link :to="'/formations/' + training.id + '?tab=Équipe'">
                            <p class="text-sm font-semibold leading-6 text-gray-900 hover:text-indigo-700">
                                <a :href="training.href" class="">{{ training.title }}</a>

                            </p>
                        </router-link>
                        <div class="mt-1 flex flex-wrap items-center gap-x-2 text-xs leading-5 text-gray-500">
                            <p class="max-w-72 flex items-center">
                                <a class=" hover:text-indigo-700">{{ training.place?.name
                                    }}</a>
                            </p>
                            <svg viewBox="0 0 2 2" class="h-0.5 w-0.5 fill-current">
                                <circle cx="1" cy="1" r="1" />
                            </svg>
                            <p>
                                {{ moment(training?.start_date).format("Do MMM YY") }} - {{
                                    moment(training?.end_date).format("Do MMM YY") }}
                            </p>
                        </div>
                    </div>
                    <div>
                        <dl class="flex w-full flex-none justify-between gap-x-8 sm:w-auto">
                            <div class="flex w-16 gap-x-2.5 mt-2 sm:mt-0">
                                <dt>
                                    <span class="sr-only">Alertes</span>
                                    <CheckCircleIcon v-if="training.status === 'resolved'"
                                        class="h-6 w-6 text-green-400" aria-hidden="true" />
                                    <ExclamationTriangleIcon v-else class="h-6 w-6 text-red-400" aria-hidden="true" />
                                </dt>
                                <dd class="text-sm leading-6 text-gray-900">{{ training.totalAlerts }}</dd>
                            </div>
                        </dl>
                    </div>
                </li>
            </ul>

            <AlertMessage type="info" title="Aucune formation pour le moment" class="mb-4"
                v-if="!isLoading && upcomingTrainings.length === 0" />

            <Pagination :current-page="currentPage" :total-pages="totalPages" @change-page="changePage" />
        </div>
    </div>
</template>

<script setup>
/* eslint-disable */
import { ref, onMounted } from 'vue';
import { ExclamationTriangleIcon, CheckCircleIcon, UserIcon, BuildingLibraryIcon } from '@heroicons/vue/24/outline';
import Pagination from '@/components/lists/PaginationTables.vue';
import SmallLoader from '@/components/app/SmallLoader.vue';
import AlertMessage from '@/components/alerts/AlertMessage.vue';
import moment from 'moment';
import 'moment/locale/fr';
import client from '@/api/client';


moment.locale('fr');

const props = defineProps({
    title: [String],
    type: [String],
});
const profile = ref(null);
const currentPage = ref(1);
const totalPages = ref(1);
const totalItems = ref(0);
const pageSize = 7;
const isLoading = ref(false);
const upcomingTrainings = ref([]);

const changePage = async (page) => {
    let urlws = '';
    isLoading.value = true;
    if (props.type === 'teachers') {
        urlws = 'api/trainings/upcoming-without-teachers';
    } else if (props.type === 'hours') {
        urlws = 'api/trainings/upcoming-without-hours';
    } else if (props.type === 'past_hours') {
        urlws = 'api/trainings/past-without-actual-hours';
    }
    try {
        const response = await client.get(`${urlws}`, {
            params: { page: page, per_page: pageSize },
        });
        upcomingTrainings.value = response.data.data;
        currentPage.value = Number(page);
        totalPages.value = response.data.last_page;
        totalItems.value = response.data.total;
        isLoading.value = false;
    } catch (error) {
        console.error('Failed to fetch trainings:', error);
    }
};

onMounted(async () => {
    profile.value = JSON.parse(localStorage.getItem('user'));
    changePage(1);
});
</script>
